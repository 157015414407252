.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #aeb4c6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #480c52;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#chatlog::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: rgb(30 41 59);

}

#chatlog::-webkit-scrollbar
{
	width: 12px;
	background-color: rgb(30 41 59);

}

#chatlog::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

#create_cafe::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: rgb(30 41 59);

}

#create_cafe::-webkit-scrollbar
{
	width: 12px;
	background-color: rgb(30 41 59);

}

#create_cafe::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

@keyframes bounce {
  0%, 100% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.8);
  }
  75% {
    transform: scale(1.1);
  }
}

.bounce:hover {
  animation: bounce 500ms;
}

.emoji-button {
  color: grey;
}

input, textarea {
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Apple Color Emoji", 
               "Noto Color Emoji", "Android Emoji", sans-serif;
}

.reaction-button {
  opacity: 0;
  transition: opacity 0.2s;
}

.message-container:hover .reaction-button {
  opacity: 1;
}

.emoji-reaction {
  cursor: pointer;
  transition: transform 0.2s;
}

.emoji-reaction:hover {
  transform: scale(1.1);
}

.group:hover .hidden {
  display: flex;
}

.modal-reaction-button {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 9999px;
  padding: 0.5rem;
  transition: all 0.2s;
}

.modal-reaction-button:hover {
  background: rgba(0, 0, 0, 0.7);
  transform: scale(1.1);
}

.image-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%; /* Creates a square aspect ratio */
  overflow: hidden;
}

.image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Responsive image modal */
.modal-image {
  max-width: 90vw;
  max-height: 70vh;
  width: auto;
  height: auto;
}

@media (min-width: 640px) {
  .modal-image {
    max-width: 80vw;
    max-height: 80vh;
  }
}

@media (min-width: 1024px) {
  .modal-image {
    max-width: 70vw;
    max-height: 85vh;
  }
}

/* Image loading and error states */
.image-loading {
  background: linear-gradient(90deg, #2c3440 0%, #374151 50%, #2c3440 100%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

/* Sidebar scrollbar styling */
.scrollbar-custom::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: rgb(30 41 59);
}

.scrollbar-custom::-webkit-scrollbar
{
	width: 12px;
	background-color: rgb(30 41 59);
}

.scrollbar-custom::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

.red-text {
  color: #f54141;
}

.video-js {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}

.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border: 2px solid white;
  width: 3em;
  height: 3em;
  line-height: 2.7em;
}

.video-js:hover .vjs-big-play-button {
  background-color: rgba(43, 51, 63, 0.7);
}

.video-js .vjs-control-bar {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background-color: rgba(43, 51, 63, 0.7);
}

.video-player-container {
  position: relative;
}

/* Add these styles */
@media (max-width: 768px) {
  .menu-slide-enter {
    transform: translateX(-100%);
  }
  
  .menu-slide-enter-active {
    transform: translateX(0);
    transition: transform 200ms ease-in-out;
  }
  
  .menu-slide-exit {
    transform: translateX(0);
  }
  
  .menu-slide-exit-active {
    transform: translateX(-100%);
    transition: transform 200ms ease-in-out;
  }
  
  .sidebar-slide-enter {
    transform: translateX(100%);
  }
  
  .sidebar-slide-enter-active {
    transform: translateX(0);
    transition: transform 200ms ease-in-out;
  }
  
  .sidebar-slide-exit {
    transform: translateX(0);
  }
  
  .sidebar-slide-exit-active {
    transform: translateX(100%);
    transition: transform 200ms ease-in-out;
  }
}

.video-js .vjs-volume-panel {
  display: flex !important;
  width: 6em !important;
}

/* .video-js .vjs-volume-control.vjs-volume-horizontal {
  width: 5em !important;
} */

/* .video-js .vjs-volume-bar.vjs-slider-horizontal {
  margin: 1.35em 0.45em !important;
} */

/* Volume panel styling */
.video-js .vjs-play-control {
  width: 3em !important;
}
.video-js .vjs-volume-panel {
  display: flex !important;
  width: 8em !important;
  /* margin-left: -14px; */
}
.vjs-mute-control {
  width: 3em !important;
}

/* .video-js .vjs-volume-bar.vjs-slider-horizontal {
  background-color: rgba(255, 255, 255, 0.2) !important;
} */

/* .video-js .vjs-volume-level {
  background-color: #3498db !important;
} */

.vjs-volume-control {
  display: flex !important;
  /* width: 50px !important; */
}

.tab {
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}
